import AuthRepository from '@/api/repositories/AuthRepository';
import CompanyRepository from '@/api/repositories/CompanyRepository';
import CustomerRepository from '@/api/repositories/CustomerRepository';
import UserRepository from '@/api/repositories/UserRepository';
import CleanTimeRepository from '@/api/repositories/CleanTimeRepository';
import JobRepository from '@/api/repositories/JobRepository';
import TimesheetRepository from '@/api/repositories/TimesheetRepository';
import AnalyticsRepository from '@/api/repositories/AnalyticsRepository';
import ImportRepository from '@/api/repositories/ImportRepository';
import ExportRepository from '@/api/repositories/ExportRepository';
import MapRepository from '@/api/repositories/MapRepository';
import FileRepository from '@/api/repositories/FileRepository';

const repositories: {
  [key: string]: {
    instance: any | null,
    repository: any,
  },
} = {
  auth: {repository: AuthRepository, instance: null},
  company: {repository: CompanyRepository, instance: null},
  customer: {repository: CustomerRepository, instance: null},
  user: {repository: UserRepository, instance: null},
  cleanTime: {repository: CleanTimeRepository, instance: null},
  job: {repository: JobRepository, instance: null},
  file: {repository: FileRepository, instance: null},
  timesheet: {repository: TimesheetRepository, instance: null},
  analytics: {repository: AnalyticsRepository, instance: null},
  import: {repository: ImportRepository, instance: null},
  export: {repository: ExportRepository, instance: null},
  map: {repository: MapRepository, instance: null},
};

/**
 *  Factory to create repositories. All created repositories are singleton instances.
 */
export const RepositoryFactory = {
  get: (name: string) => {
    if (!repositories[name].instance) {
      repositories[name].instance = new repositories[name].repository();
    }
    return repositories[name].instance!;
  },
};

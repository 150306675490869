import Parseable from '@/misc/Parseable';
import User from '@/models/User';
import moment from 'moment';

export class VersionControl extends Parseable {
  public vcAuthor!: User;
  public vcAuthorId!: string;
  public vcIsLogEntity!: boolean;
  public vcLogDate!: string;
  public vcOrigin!: string;

  public parseToObject(): Partial<VersionControl> {
    return {...this};
  }

  /**
   * Compares two VersionControl objects, if they have the same values return true, else return false
   * @param obj1 is the original object
   * @param obj2 should be based on obj1
   */
  public static compare(obj1: object, obj2: object): boolean {
    const attributes = Object.keys(obj2).filter((item: string) => !Object.values(IrrelevantKeys).includes(item));
    for (const attribute of attributes) {
      if (!(obj1?.[attribute]) && !(obj2?.[attribute])) {
        continue;
      }
      // case attribute is Array
      if (Array.isArray(obj1?.[attribute])) {
        if (obj1?.[attribute].length !== obj2?.[attribute].length) {
          return false;
        }
        for (let i = 0; i < obj1?.[attribute].length; i++) {
          if (obj1?.[attribute] instanceof Object) {
            if (!(this.compare(obj1?.[attribute], obj2?.[attribute]))) {
              return false;
            }
          } else if (obj1?.[attribute][i] !== obj2?.[attribute][i]) {
            return false;
          }
        }
        // case attribute is another object => recursion
      } else if (obj1?.[attribute] instanceof Object) {
        if (!(this.compare(obj1?.[attribute], obj2?.[attribute]))) {
          return false;
        }
      } else if (obj1?.[attribute] !== obj2?.[attribute]) {
        return false;
      }
    }
    return true;
  }

  /**
   * Compares the LogDate of two VersionControl Objects
   * @param vc1
   * @param vc2
   */
  public static compareLogDate(vc1: VersionControl, vc2: VersionControl) {
    if (moment(vc1.vcLogDate).isSame(vc2.vcLogDate)) {
      return 0;
    }
    if (moment(vc1.vcLogDate).isAfter(vc2.vcLogDate)) {
      return 1;
    } else {
      return -1;
    }
  }
}


export enum IrrelevantKeys {
  'createdAt',
  'updatedAt',
  'vcAuthor',
  'vcIsLogEntity',
  'vcLogDate',
  'vcOrigin',
  'usersCount',
  'customersCount',
  'contactPersons',
  'id',
  'tasks',
  'users',
  'customers',
  'geoPosition',
  'colorCode',
  'company',
  'cleanTimes',
  'plannedCleanTimes',
  'availableAtCleanTimes',
  'managedLocations',
  'locationsCount',
  'inviteAccepted',
  'userHasNoEmail',
  'locationsCountActive',
  'locations',
  'customer',
  'managers',
  'areas',
  'wasEdited',
}















































import {Component, Vue, Watch} from 'vue-property-decorator';
import {Route} from 'vue-router';
import {namespace} from 'vuex-class';
import User from '@/models/User';

const AuthStore = namespace('auth');

/**
 * Entry Vue class. Sets up the application base layout.
 */
@Component({
  components: {
    HeaderBarComponent: () => import(
        '@/components/shared/HeaderBar.component.vue'),
    NavigationBarComponent: () => import(
        '@/components/shared/NavigationBar.component.vue'),
  },
})
export default class App extends Vue {
  @AuthStore.Action('refreshToken')
  private refreshToken!: () => Promise<void>;
  @AuthStore.Getter('user')
  private _user!: User;

  /**
   * Flag for showing navigation drawer
   */
  private noNavigation: boolean = false;

  /**
   * All route names were no navigation drawer should be shown
   */
  private routeNamesWithoutNavigation: string[] = [
    'setPassword',
    'login',
    'imprint',
    'privacy-notes-app',
    'privacy-notes-web',
    'terms-and-conditions',
  ];

  private created() {
    // if user is stored in local storage, try to refresh token on opening the app
    if (this._user) {
      // no try/catch needed, because error is thrown and handled in EntityBaseRepository. In theory this should log out
      // the user, if the refreshToken expires (after 90 days). Every time the token is refreshed, the refresh token is
      // refreshed too, so this should only happen after 90 days of not using
      this.refreshToken();
    }
  }

  // OPTIMIZE Is there a better way to check if navigation should be shown?
  @Watch('$route', {immediate: true, deep: true})
  private onUrlChange(route: Route) {
    this.noNavigation = this.routeNamesWithoutNavigation.includes(route.name!);
  }

  /**
   * Support function to resolve notification icon
   */
  private resolveNotificationIcon(type: string): string {
    const iconTypes: { [key: string]: string } = {
      success: 'mdi-check-circle',
      error: 'mdi-close-circle',
      info: 'mdi-information-variant',
      warning: 'mdi-alert-circle',
    };
    return iconTypes[type];
  }
}

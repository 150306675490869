import EntityBaseRepository from '@/api/repositories/EntityBaseRepository';
import User from '@/models/User';
import CleanTime from '@/models/CleanTime';
import {FindAllResponse} from '@/interfaces/Responses';
import {KeyOf} from '@/misc/Parseable';
import UserRole from '@/models/user-attributes/UserRole';
import Substitute from '@/models/Substitute';

export default class UserRepository extends EntityBaseRepository {

  /**
   * Loads only users who are no admin!
   */
  public loadUsers(companyId: string, populate: KeyOf<User>[] = [], queryOptions: {
    availableAtCleanTimes: boolean,
  }): Promise<FindAllResponse<User>> {
    return this.axiosClient.get(`user?company=${companyId}&populate=${populate.join(',')}`);
  }

  public loadUserVc(id: string, skip?: number, limit?: number): Promise<FindAllResponse<User>> {
    return this.axiosClient.get(`user?vcOriginId=${id}&vcIsLogEntity=true&populate=vcAuthor`);
  }

  public loadUserByEmail(email: string): Promise<any> {
    return this.axiosClient(`user?email=${email}`);
  }

  public loadSingleUser(userId: string): Promise<any> {
    return this.axiosClient(`user/${userId}`);
  }

  public deleteUser(user: Partial<User>): Promise<any> {
    return this.axiosClient.delete(`user/${user.id}`);
  }

  public createUser(user: User): Promise<any> {
    return this.axiosClient.post('user', user);
  }

  public updateUser(user: Partial<User>): Promise<any> {
    return this.axiosClient.patch(`user/${user.id}`, user);
  }

  public setUserStatus(payload: {
    id: string,
    active: boolean,
    preview?: boolean,
    force?: boolean,
  }): Promise<Partial<User>> {
    let queryParams;
    if (payload.preview || payload.force) {
      if (payload.preview && payload.force || payload.preview) { // prefer preview over force if both  params are set
        queryParams = '?preview';
      } else {
        queryParams = '?force';
      }
    }
    return this.axiosClient.patch(`user/${payload.id}${queryParams}`, {active: payload.active});
  }



  public loadUserAttributes(): Promise<any> {
    return this.axiosClient.get(`userAttributes`);
  }

  public loadSubstitutes(
    where: Partial<Pick<Substitute, 'userId' | 'substituteUserId' | 'cleanTimeId'>>,
    populate: KeyOf<Substitute>[]): Promise<FindAllResponse<Substitute>> {
    const whereQuery = Object.keys(where).filter((key: string) => !!where[key]).map((key: string) => `${key}=${where[key]}`).join('&');
    return this.axiosClient.get(`substitute?populate=${populate.join(',')}&${whereQuery}`);
  }

  public loadSubstitute(id: string): Promise<Substitute> {
    return this.axiosClient.get(`substitute/${id}`);
  }

  public createSubstitute(substituteBody: Substitute): Promise<Substitute> {
    return this.axiosClient.post(`substitute`, substituteBody);
  }

  public updateSubstitute(id: string, substituteBody: Partial<Substitute>): Promise<Substitute> {
    return this.axiosClient.patch(`substitute/${id}`, substituteBody);
  }
  public deleteSubstitute(id: string): Promise<void> {
    return this.axiosClient.delete(`substitute/${id}`);
  }

  public changePassword(payload: { user: User, passwordOld: string, password: string }): Promise<void> {
    const params = {passwordOld: payload.passwordOld, password: payload.password};
    return this.axiosClient.patch(`user/${payload.user.id}`, params);
  }

  public resendInvitation(userId: string): Promise<void> {
    return this.axiosClient.post(`user/${userId}/resendInvite`);
  }

  public calculateWorkHoursLeft(currentUsers: User[], currentCleanTime: CleanTime): Promise<any> {
    return this.axiosClient.post('user/calculateWorkHoursLeft', {
      users: currentUsers, cleanTime: currentCleanTime,
      cleanTimeID: currentCleanTime.id ? currentCleanTime.id : 'new',
    });
  }

  public deleteUserRole(roleId: string): Promise<any> {
    return this.axiosClient.delete(`role/${roleId}`);
  }

  public updateUserRole(role: UserRole): Promise<UserRole> {
    return this.axiosClient.patch(`role/${role.id}`, role);
  }

  public createNewUserRole(role: UserRole): Promise<any> {
    return this.axiosClient.post('role', role);
  }

  public loadUserRoles(companyId: string, populate: string[]): Promise<FindAllResponse<UserRole>> {
    return this.axiosClient.get(`role?companyId=${companyId}&populate=${(populate ?? []).join(',')}`);
  }

}

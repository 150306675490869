import Parseable from '@/misc/Parseable';
import WorkSession from '@/models/WorkSession';
import User from '@/models/User';
import Location from '@/models/Location';
import CleanTime from '@/models/CleanTime';
import Customer from '@/models/Customer';
import CleanTimeOccurrence from '@/models/CleanTimeOccurrence';
import { JobStatusIdentification, JobStatusType } from '@/misc/JobStatusIdentification';

/**
 * A job represents the planning or the completed work of ACTTS from Users in Locations in a certain clean time.
 */
export default class Job extends Parseable {

    public static parseFromObject(object: Partial<Job>): Job {
        const job = new Job();

        Object.assign(job, object);

        job.cleanTime = CleanTime.parseFromObject(object.cleanTime!);
        job.location = Location.parseFromObject(object.location!);
        job.workSessions = WorkSession.parseFromArray(object.workSessions!) as WorkSession[];
        job.users = User.parseFromArray(object.cleanTime!.plannedUsers) as User[];

        job.status = JobStatusIdentification.identify(job);

        return job;
    }

    // New Structure of the Job
    public users: User[] = [];
    public cleanTime!: CleanTime;
    public customer?: Customer;
    public customerId?: string;
    public location!: Location;
    public cleanTimeOccurrence!: CleanTimeOccurrence;
    public workSessions: WorkSession[] = [];
    public queryDate ?: string = ''; // used to filter job in calendar week view
    public status!: JobStatusType;

    public parseToObject(): any {
        const tmp: any = {...this};
        tmp.cleanTime = tmp.cleanTime.parseToObject();
        tmp.location = tmp.location.parseToObject();
        tmp.workSessions = tmp.workSessions.slice();
        tmp.users = tmp.users.slice();
        return tmp;
    }
}

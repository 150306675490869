import store from '@/store';
import {Permission} from '@/misc/enums/permission.enum';
import User from '@/models/User';

const storeUser = (): User => {
  return store.getters['auth/user'];
};
/**
 * Vue plugin for permission handling based of the current user role. Provides support function about user permissions and user
 * role handling also.
 */
const UserRoleHandler = {
  install(Vue: any) {

    /**
     * Returns the current user role. If no user is defined, it returns undefined.
     */
    const getUserRole = () => {
      if (storeUser()) {
        return storeUser().role;
      } else {
        return undefined;
      }
    };

    /**
     * Checks if user has the given role. If no user is defined, it returns undefined.
     * @param role
     */
    const hasUserRole = (role: string) => {
      if (storeUser()) {
        return storeUser().role!.name === role;
      } else {
        return undefined;
      }
    };

    // TODO: find out whether it is possible to generate functions to check the role of the user for each role like
    // for (Keys in userRoles) => [is'role']: (user)

    /**
     * Checks if the current user has the given permission
     * @param permission
     * @param user
     */
    const hasUserPermission = (permission: Permission, user: User = storeUser()): boolean => {
      if (!storeUser()) {
        return false;
      }

      if (isSuperAdmin(user)) {
        return true;
      }

      if (isTenantAdmin(user) &&
        (!permission.includes('COMPANY')
          || permission === Permission.COMPANY_READ_OWN
          || permission === Permission.COMPANY_UPDATE_OWN)) {
        return true;
      }
      if (permission.endsWith('OWN')) {
        const perm = permission.replace('_OWN', '') as Permission;
        return (storeUser().role!.permissions.includes(permission) || storeUser().role!.permissions.includes(perm));
      }
      return storeUser().role!.permissions.includes(permission);
    };

    const isSuperAdmin = (user: User = storeUser()) => {
      if (user) {
        return user.role!.superAdmin;
      } else {
        return false;
      }
    };
    const isTenantAdmin = (user: User = storeUser()) => {
      if (user) {
        return user.role!.tenantAdmin;
      } else {
        return false;
      }
    };

    /**
     * checks if the user has at least one of the defined permissions
     * @param permissions
     */
    const hasSomePermissions = (permissions: Permission[]): boolean | undefined => {
      return permissions.some((permission: Permission) => hasUserPermission(permission));
    };

    const hasEveryPermission = (permissions: Permission[]): boolean | undefined => {
      return permissions.every((permission: Permission) => hasUserPermission(permission));
    };

    const canUpdateSpecificUser = (userID: string): boolean | undefined => {
      return isSuperAdmin() ||
        isTenantAdmin() ||
        storeUser().role?.permissions.includes(Permission.USER_UPDATE) ||
        (storeUser().id === userID && storeUser().role?.permissions.includes(Permission.USER_UPDATE_OWN));
    };

    const isLoggedInUser = (userId: string) => {
      return storeUser().id === userId;
    };

    const userRoleFns = {
      isTenantAdmin,
      hasPermission: hasUserPermission,
      hasSomePermissions,
      canUpdateSpecificUser,
      hasEveryPermission,
      getRole: getUserRole,
      hasRole: hasUserRole,
      isSuperAdmin,
      isLoggedInUser,
    };
    Vue.prototype.$userRoleHandler = userRoleFns;
    Vue.userRoleHandler = userRoleFns;
  },
};
export default UserRoleHandler;

import Parseable from '@/misc/Parseable';

export default class ContactPerson extends Parseable {

    public static parseFromObject(object: Partial<ContactPerson>): ContactPerson {
        const contactPerson = new ContactPerson();
        Object.assign(contactPerson, object);
        return contactPerson;
    }

    public id?: string;
    public firstName?: string;
    public lastName?: string;
    public email?: string;
    public phone?: string;

    public label?: string | null;
    public isInAppVisible?: boolean;

    constructor() {
        super();
        this.isInAppVisible = true;
    }

    public get initials(): string {
        if (this.firstName && this.lastName) {
            return `${this.firstName[0]}${this.lastName[0]}`.toUpperCase();
        } else {
            return '';
        }
    }

    public get fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }
    /**
     * Returns a swallow ContactPerson copy
     */
    public copy(): ContactPerson {
        return ContactPerson.parseFromObject(this.parseToObject());
    }

    public parseToObject(): Partial<ContactPerson> {
        return {...this};
    }
}

import Parseable from '@/misc/Parseable';
import Task from '@/models/Task';
import User from '@/models/User';

export default class Area extends Parseable {

    public static parseFromObject(object: Partial<Area>): Area {
        const area = new Area();
        Object.assign(area, object);
        return area;
    }

    public id!: string;
    public name!: string;
    public description?: string;
    public tasks: Task[] = [];
    public order!: number;

    public plannedUsers: Partial<User>[] = [];

    public parseToObject(): any {
        return {...this};
    }

    // TODO: remove
    public size!: number;
    public location!: string;
}

import EntityBaseRepository from '@/api/repositories/EntityBaseRepository';

export default class AnalyticsRepository extends EntityBaseRepository {

  public getNavSearchBarResults(queryData: string, filterData: string, company: string = ''): Promise<any> {
    return this.axiosClient.get(`/analytics/search?query=${queryData}&types=${filterData}&company=${company}`);
  }

  public async getDashboardData(companyId: string): Promise<any> {
    // RN-380 only as temp solution
    const chartDataRequest = this.axiosClient.get(`/analytics/dashboard/chartData?company=${companyId}`);
    const customerCountRequest = this.axiosClient.get(`/analytics/dashboard/customerCount?company=${companyId}`);
    const locationCountRequest = this.axiosClient.get(`/analytics/dashboard/locationCount?company=${companyId}`);
    const userCountRequest = this.axiosClient.get(`/analytics/dashboard/userCount?company=${companyId}`);
    const workSessionCountRequest = this.axiosClient.get(`/analytics/dashboard/workSessionCount?company=${companyId}`);

    // RN-380 while implementing the new theme make everything an own request to improve loading speed

    const [
      chartData,
      customerCount,
      locationCount,
      userCount,
      workSessionCount,
    ] =
      await Promise.all([
        chartDataRequest,
        customerCountRequest,
        locationCountRequest,
        userCountRequest,
        workSessionCountRequest,
      ]) as any;

    const dashboardData = {
      chartData: chartData.workSessionsPerMonth,
      customerCount: customerCount.customerCount,
      userCount: userCount.userCount,
      locationCount: locationCount.locationCount,
      workSessionCount: workSessionCount.workSessionCount,
    };

    return dashboardData;
  }
}
